import { stringify } from 'query-string';

import { toDateString } from '../../common/dates';
import {
  ParticipantDataPermissions,
  SupportAccessRight,
  TodayScores,
  TOSData,
  UserAuthorizedApplication,
  UserData,
  UserHeartRateData,
  UserIntegration,
  UserNotesData,
  UserParticipation,
} from '../../common/types';
import { toUserNotes } from '../converters/common';
import {
  toConnectIntegrationResponse,
  toHeartRate,
  toParticipantDataPermissions,
  toSupportAccessRight,
  toTOS,
  toUserAuthorizedApplication,
  toUserInfo,
  toUserIntegration,
  toUserParticipation,
} from '../converters/personal';
import { parseDate } from '../converters/time';
import {
  ApiConnectIntegrationResponse,
  ApiParticipantDataPermissions,
  ApiTodayScores,
  GetApiUserApplicationsResponse,
  GetApiUserIntegrationsResponse,
  GetApiUserNotesResponse,
  GetApiUserParticipationsResponse,
  GetOAuthInstallResponse,
  GetSupportAccessRightsResponse,
  GetTOSResponse,
  GetUserHeartRateResponse,
  GetUserInfoResponse,
  PostConnectIntegrationPayload,
  PostConnectIntegrationResponse,
  PostDisconnectIntegrationPayload,
  PostTOSPayload,
} from '../types';
import { getAccountAPIUrl, getAccountAPIV2Url, getAPIUrl } from '../urls';
import { doFetch } from './index';

export async function fetchUserParticipations() {
  const uri = getAPIUrl('me/teams/participant');
  return doFetch<GetApiUserParticipationsResponse, UserParticipation[]>({
    method: 'GET',
    uri,
    converter: (data) => data.teams.map(toUserParticipation),
  });
}

export async function deleteUserParticipation(memberID: string) {
  const uri = getAPIUrl(`me/teams/participant/${memberID}`);
  return doFetch<null, { memberID: string }>({
    method: 'DELETE',
    uri,
    converter: () => ({ memberID }),
  });
}

export async function patchUserParticipationDataAccessPermissions(
  memberID: string,
  payload: ParticipantDataPermissions,
) {
  const uri = getAPIUrl(
    `me/teams/participant/${memberID}/data-access-permissions`,
  );
  return doFetch<
    ApiParticipantDataPermissions,
    { memberID: string; dataPermissions: ParticipantDataPermissions },
    ApiParticipantDataPermissions
  >({
    method: 'PATCH',
    uri,
    converter: (data) => ({
      memberID,
      dataPermissions: toParticipantDataPermissions(data),
    }),
    payload: { share_notes: payload.shareTags },
  });
}

export async function fetchUserApplications() {
  const uri = getAPIUrl(`me/applications`);
  return doFetch<GetApiUserApplicationsResponse, UserAuthorizedApplication[]>({
    method: 'GET',
    uri,
    converter: (data) =>
      data.authorized_applications.map(toUserAuthorizedApplication),
  });
}

export async function deleteUserApplication(appID: string) {
  const uri = getAPIUrl(`me/applications/${appID}`);
  return doFetch<null, { appID: string }>({
    method: 'DELETE',
    uri,
    converter: () => ({ appID }),
  });
}

export async function fetchUserIntegrations() {
  const uri = getAccountAPIV2Url(`connections`);
  return doFetch<GetApiUserIntegrationsResponse, UserIntegration[]>({
    method: 'GET',
    uri,
    // filter out anything without a web_url
    converter: (data) =>
      data.partners.filter((partner) => partner.web_url).map(toUserIntegration),
  });
}

export async function getInstallIntegration(
  integrationName: string | undefined,
) {
  const uri = getAccountAPIV2Url(`oauth/${integrationName}/install`);
  return doFetch<GetOAuthInstallResponse, GetOAuthInstallResponse>({
    method: 'GET',
    uri,
    converter: (data) => data,
  });
}

export async function postDisconnectIntegration(
  payload: PostDisconnectIntegrationPayload,
) {
  const uri = getAccountAPIV2Url(`${payload.integrationId}/disconnect`);
  return doFetch<null, null, PostDisconnectIntegrationPayload>({
    method: 'POST',
    uri,
    converter: (data) => data,
    payload,
  });
}

export async function postConnectIntegration(
  payload: PostConnectIntegrationPayload,
) {
  const uri = getAccountAPIV2Url(
    `connect?state=${payload.state}&code=${payload.code}&id=${payload.id}`,
  );
  return doFetch<
    ApiConnectIntegrationResponse,
    PostConnectIntegrationResponse,
    PostConnectIntegrationPayload
  >({
    method: 'POST',
    uri,
    converter: (
      data: ApiConnectIntegrationResponse,
    ): PostConnectIntegrationResponse => toConnectIntegrationResponse(data),
    payload,
  });
}

export async function fetchUserNotes() {
  const uri = getAccountAPIUrl('notes');
  return doFetch<GetApiUserNotesResponse, UserNotesData>({
    method: 'GET',
    uri,
    converter: toUserNotes,
  });
}

export async function fetchUserHeartRate(date: string, timezoneName: string) {
  const uri = getAccountAPIUrl(
    `timeseries-hr?date=${date}&tz_name=${timezoneName}`,
  );
  return doFetch<GetUserHeartRateResponse, UserHeartRateData>({
    method: 'GET',
    uri,
    converter: (response) =>
      response.status === 'available'
        ? {
            status: response.status,
            heartRate: response.heart_rate.map((hr) => toHeartRate(hr)),
            timezone: response.timezone,
          }
        : response,
  });
}

export async function fetchTOS() {
  const uri = getAPIUrl('account/terms-of-service');
  return doFetch<GetTOSResponse, TOSData>({
    method: 'GET',
    uri,
    converter: toTOS,
  });
}

export async function postTOS(payload: PostTOSPayload) {
  const uri = getAPIUrl('account/terms-of-service');
  return doFetch<{}, {}, PostTOSPayload>({
    method: 'POST',
    uri,
    converter: (v) => v,
    payload,
  }).then(() => fetchTOS());
}

export async function fetchUserProfile() {
  const uri = getAPIUrl('me');
  return doFetch<GetUserInfoResponse, UserData>({
    method: 'GET',
    uri,
    converter: toUserInfo,
  });
}

export function deleteAccount() {
  const uri = getAPIUrl('me');

  return doFetch<{}>({
    method: 'DELETE',
    uri,
    converter: () => ({}),
  });
}

export function fetchSupportAccessRights() {
  const uri = getAPIUrl(`me/support-access`);

  return doFetch<GetSupportAccessRightsResponse, SupportAccessRight[]>({
    method: 'GET',
    uri,
    converter: (d) => d.support_access_rights.map(toSupportAccessRight),
  });
}

export function deleteSupportAccessRight(id: string) {
  const uri = getAPIUrl(`me/support-access/${id}`);

  return doFetch<{}, { id: string }>({
    method: 'DELETE',
    uri,
    converter: () => ({ id }),
  });
}

export function fetchTodayScores(): Promise<TodayScores> {
  const uri = getAccountAPIUrl(
    'daily-scores',
    stringify({ date: toDateString(new Date()) }),
  );

  return doFetch<ApiTodayScores, TodayScores>({
    uri,
    method: 'GET',
    converter: (v) => ({
      dailySleep: v.daily_sleep,
      dailyActivity: v.daily_activity,
      dailyReadiness: v.daily_readiness,
      date: parseDate(v.date),
    }),
  });
}
