import { omit } from 'lodash';

import { isMetricKey, MetricKey, shownKeys } from '../../common/metrics';
import { logException } from '../../common/sentry';
import {
  HeartRate,
  ParticipantDataPermissions,
  SupportAccessRight,
  TOSData,
  TrendsQuery,
  UserAuthorizedApplication,
  UserData,
  UserEmail,
  UserIntegration,
  UserParticipation,
} from '../../common/types';
import { isDefined } from '../../common/utils';
import {
  defaultTrendsConfigurationName,
  defaultTrendsQuery,
} from '../defaults';
import {
  ApiConnectIntegrationResponse,
  ApiHeartRate,
  ApiParticipantDataPermissions,
  ApiSupportAccessRight,
  ApiUserAuthorizedApplication,
  ApiUserEmail,
  ApiUserIntegration,
  ApiUserParticipation,
  GetTOSResponse,
  GetUserInfoResponse,
  PostConnectIntegrationResponse,
  TrendsSettingsPayload,
  TrendsSettingsResponse,
} from '../types';
import { parseDate, parseTimestamp } from './time';

export function toTOS({
  terms_of_service_version,
  terms_of_service_url,
  privacy_policy_version,
  privacy_policy_url,
  accepted,
}: GetTOSResponse): TOSData {
  return {
    termsOfServiceVersion: terms_of_service_version,
    termsOfServiceUrl: terms_of_service_url,
    privacyPolicyVersion: privacy_policy_version,
    privacyPolicyUrl: privacy_policy_url,
    accepted,
  };
}

export function toUserInfo(data: GetUserInfoResponse): UserData {
  const {
    email,
    latest_sync: latestSync,
    analytics_uid: analyticsUID,
    accepted_tos: acceptedTOS,
    user_uid: uid,
    user_settings: userInfo,
    features,
    subscription,
  } = data;
  // FIXME: remove this once optionality in GetUserInfoResponse is fixed
  if (!features) {
    logException(
      new Error('features missing'),
      omit(data, 'name', 'email', 'user_settings'),
    );
  }

  return {
    analyticsUID,
    acceptedTOS,
    uid,
    email,
    latestSync: latestSync ? parseTimestamp(latestSync) : null,
    userInfo: {
      gender: userInfo.gender ?? 'other',
      weight: userInfo.weight ?? undefined,
      height: userInfo.height ?? undefined,
      age: userInfo.age ?? undefined,
      units: userInfo.units ?? undefined,
    },
    features: features ?? {},
    subscription: {
      isActive: subscription.is_active,
      state: subscription.state,
      startDate: subscription.start_date
        ? parseDate(subscription.start_date)
        : undefined,
      endDate: subscription.end_date
        ? parseDate(subscription.end_date)
        : undefined,
    },
    isHipaa: data.hipaa?.enabled,
  };
}

export function toUserEmail({
  email,
  email_uid: id,
  primary,
  verified,
}: ApiUserEmail): UserEmail {
  return {
    email,
    id,
    primary,
    verified,
  };
}

export function toParticipantDataPermissions(
  data: ApiParticipantDataPermissions,
): ParticipantDataPermissions {
  return {
    shareTags: data.share_notes,
  };
}

export function toUserParticipation(
  data: ApiUserParticipation,
): UserParticipation {
  return {
    joinedAt: parseTimestamp(data.joined_at),
    memberID: data.participant_uid,
    groupID: data.study_uid,
    groupName: data.study_name,
    organizationName: data.organization_name,
    product: data.product,
    dataPermissions: data.data_permissions
      ? toParticipantDataPermissions(data.data_permissions)
      : undefined,
  };
}

export function toUserAuthorizedApplication({
  uid,
  app_name,
  site_name,
  url,
  privacy_policy_url,
  terms_of_service_url,
}: ApiUserAuthorizedApplication): UserAuthorizedApplication {
  return {
    url,
    id: uid,
    appName: app_name,
    siteName: site_name,
    privacyPolicyUrl: privacy_policy_url,
    termsOfServiceUrl: terms_of_service_url,
  };
}

export function toUserIntegration({
  id,
  name,
  web_url,
  oauth_parameters,
  connected,
  redirect_uri,
  icon_url,
  mobile_authorization_uri,
}: ApiUserIntegration): UserIntegration {
  return {
    id,
    integrationName: name,
    webUrl: web_url,
    oauthParams: oauth_parameters,
    connected,
    redirectUri: redirect_uri,
    iconUrl: icon_url,
    mobileAuthorizationUri: mobile_authorization_uri,
  };
}

export function toConnectIntegrationResponse({
  app_id,
  enterprise_id,
  oura_partner,
  oura_user_id,
  slack_user_id,
  slack_workspace_id,
}: ApiConnectIntegrationResponse): PostConnectIntegrationResponse {
  return {
    appId: app_id,
    enterpriseId: enterprise_id,
    ouraPartner: oura_partner,
    ouraUserId: oura_user_id,
    slackUserId: slack_user_id,
    slackWorkspaceId: slack_workspace_id,
  };
}

// If a user has metrics that are no longer allowed to be selected, drop them
function isValidQueryMetric(key: unknown): key is MetricKey {
  return isMetricKey(key) && shownKeys.includes(key);
}

export function toQueryState(
  trendsSettings: TrendsSettingsResponse,
): TrendsQuery {
  // Note: even though the data structure supports multiple named configurations,
  // we only use the first one for now.
  const settings = trendsSettings.configurations[0];
  if (!settings) {
    // This really shouldn't happen, but occurs in our integration tests.
    console.error(
      `No settings found in response (using defaults): ${JSON.stringify(
        trendsSettings,
      )}`,
    );

    return {
      ...defaultTrendsQuery,
    };
  }

  return {
    timeAggregation: settings.time_aggregation,
    subqueries: settings.charts
      .map((c) =>
        // If a user has metrics in the query that are no longer allowed to be selected,
        // drop the query
        isValidQueryMetric(c.left_y) &&
        (c.right_y == null || isValidQueryMetric(c.right_y))
          ? {
              key: c.left_y,
              y1: c.left_y,
              y2: c.right_y,
            }
          : undefined,
      )
      .filter(isDefined),
    setLocally: false,
  };
}

export function toTrendsSettings(
  queryState: TrendsQuery,
): TrendsSettingsPayload {
  return {
    settings_type: 'trends',
    version: 1,
    configurations: [
      {
        name: defaultTrendsConfigurationName,
        time_aggregation: queryState.timeAggregation,
        charts: queryState.subqueries.map((q) => ({
          type: 'trend',
          left_y: q.y1,
          right_y: q.y2,
        })),
      },
    ],
  };
}

export function toSupportAccessRight({
  uid,
  requester_email,
  state,
  expires_at,
}: ApiSupportAccessRight): SupportAccessRight {
  return {
    id: uid,
    requesterEmail: requester_email,
    state,
    expiresAt: parseTimestamp(expires_at),
  };
}

export function toHeartRate(d: ApiHeartRate): HeartRate {
  return {
    bpm: d.bpm,
    source: d.source,
    quality: d.quality,
    restorative: d.restorative,
    timestamp: parseTimestamp(d.timestamp),
  };
}
